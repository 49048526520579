import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/LayoutDocs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ButtonsNavigation = makeShortcode("ButtonsNavigation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Introduction`}</h3>
    <p>{`We use URL parameters to control the manner in which charts are displayed. For example, we tell your layout that the chart is being displayed in an iFrame on a website. The layout will adjust accordingly.`}</p>
    <hr></hr>
    <h3>{`Example`}</h3>
    <h4>{`Direct link`}</h4>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`https://www.inflowchart.com/chart/examples/chart`}</code></p>
    <h4>{`Embedded link`}</h4>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`https://www.inflowchart.com/chart/examples/chart?embedded=true`}</code></p>
    <hr></hr>
    <h3>{`Parameters`}</h3>
    <ul>
      <li parentName="ul">{`Using the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`?embedded=true`}</code>{` URL parameter tells the viewer component that the chart is embedded on another website trough the iframe. Note how the embedded version shows the fullscreen button in the header.`}</li>
    </ul>
    <hr></hr>
    <h3>{`URL shortener`}</h3>
    <p>{`We provide short links for easy sharing using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`inflw.ch/IDHERE`}</code>{` format, like `}<a parentName="p" {...{
        "href": "https://inflw.ch/DjIId"
      }}>{`inflw.ch/DjIId`}</a></p>
    <hr></hr>
    <ButtonsNavigation linkPrevious="Back" linkPreviousTo="/docs/introduction" mdxType="ButtonsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      